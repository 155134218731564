import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import KRGlue from '@lyracom/embedded-form-glue'

import 'bootstrap/dist/css/bootstrap.min.css'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'

import { env } from './config'
import { xmlAction, updateBillStatus, generateFormToken } from './functions'

const logo = require('./logo.png') as string
const hmacSHA256 = require('crypto-js/hmac-sha256')
const Hex = require('crypto-js/enc-hex')

const App = () => {
	const [isLoading, setLoading] = useState<boolean>(false)

	const [predFormDisplay, setPredFormDisplay] = useState<boolean>(true)
	const [payzenFormDisplay, setPayzenFormDisplay] = useState<boolean>(false)

	const [alert, setAlert] = useState<any>(null)
	const showAlert = (alert: any): void => {
		if (alert) setPredFormDisplay(false)
		else {
			setPredFormDisplay(true)
			setPayzenFormDisplay(false)
		}
		setAlert(alert)
	}

	const [clientNo, setClientNo] = useState<string>('')
	const [billNo, setBillNo] = useState<string>('')

	const submitForm = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.preventDefault()
		setLoading(true)

		if (predFormDisplay)
			try {
				const billResponse: any = await xmlAction('FACTURE_INFORMATIONS', clientNo, billNo)
				const amountStr: string = billResponse.split('&lt;SOLDE&gt;')[1]?.split('&lt;/SOLDE&gt;')[0]

				if (amountStr) {
					const amount: number = parseFloat(amountStr.replace(',', '.'))
					if (amount) {
						showAlert({ type: 'success', title: `La facture ${billNo} d'un montant de ${amountStr}€ a été trouvée.`, text: 'pay' })
						await generatePayzenForm(amount)
						setPayzenFormDisplay(true)
					} else if (amount === 0) showAlert({ type: 'success', title: `Le paiement est enregistré pour la facture ${billNo}.`, text: 'Nous vous remercions pour votre confiance, à bientôt sur le site des Laboratoires Pred !' })
				} else showAlert({ type: 'danger', title: "Aucune facture n'a été trouvée avec ces informations.", text: 'noBillFound' })
			} catch (err: any) {
				console.error(err)
				showAlert({ type: 'danger', title: "Une erreur s'est produite", text: 'internalServerError' })
			}
		else {
			showAlert(null)
		}

		setLoading(false)
	}

	useEffect(() => {
		;(async (): Promise<void> => {
			await axios(env('CORS_BYPASS_URL'))
		})()
	}, [])

	const generatePayzenForm = async (amount: number): Promise<void> => {
		try {
			const formToken: string = await generateFormToken(clientNo, billNo, amount)
			await KRGlue.loadLibrary(env('LYRA_API_URL'), `${env('PAYZEN_API_USER')}:${env('PAYZEN_API_PRODUCTION_PUBLIC_KEY')}`).then(({ KR }: any) => {
				KR.onSubmit(async (e: any) => {
					const answer = e.clientAnswer
					const hash = e.hash
					const answerHash = Hex.stringify(hmacSHA256(JSON.stringify(answer), env('PAYZEN_API_PRODUCTION_HMAC_KEY')))
					if (hash === answerHash) {
						const err: any = await updateBillStatus(amount, clientNo, billNo)
						if (err) showAlert({ type: 'danger', title: "Une erreur s'est produite", text: 'internalServerError' })
						else {
							setPayzenFormDisplay(false)
							showAlert({ type: 'success', title: `Le paiement est enregistré pour la facture ${billNo}.`, text: 'Nous vous remercions pour votre confiance, à bientôt sur le site des Laboratoires Pred !' })
						}
					} else showAlert({ type: 'danger', title: "Une erreur s'est produite", text: 'internalServerError' })
				})
				KR.setFormConfig({
					formToken,
					'kr-language': 'fr-FR'
				})
					.then(({ KR }: any) => KR.addForm('#myPaymentForm'))
					.then(({ KR, result }: any) => KR.showForm(result.formId))
			})
		} catch (err: any) {
			console.error(err)
		}
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 100 }}>
			<img src={logo} alt='logo' style={{ marginBottom: 24 }} />

			<h1 style={{ fontSize: 32, marginBottom: 12 }}>Paiement facture client</h1>

			<Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				{alert ? (
					<Alert variant={alert.type} style={{ width: 366 }}>
						<Alert.Heading>{alert.title}</Alert.Heading>
						{alert.text === 'pay' ? (
							<div>
								Veuillez remplir le formulaire de paiement ci-dessous pour régler la facture.
							</div>
						) : alert.text === 'noBillFound' ? (
							<div>
								Vous pouvez réessayer en vérifiant les informations saisies ou{' '}
								<a href={env('PRED_CONTACT_URL')} target='_blank' rel='noreferrer' style={{ color: 'unset' }}>
									nous contacter
								</a>{' '}
								si le problème persiste.
							</div>
						) : alert.text === 'internalServerError' ? (
							<div>
								Vous pouvez réessayer ou{' '}
								<a href={env('PRED_CONTACT_URL')} target='_blank' rel='noreferrer' style={{ color: 'unset' }}>
									nous contacter
								</a>{' '}
								si le problème persiste.
							</div>
						) : (
							<div>{alert.text}</div>
						)}
					</Alert>
				) : (
					<></>
				)}

				{predFormDisplay ? (
					<div>
						<Form.Text style={{ marginBottom: 32, display: 'block', textAlign: 'center' }}>Veuillez saisir vos informations de facturation.</Form.Text>

						<Form.Group className='mb-3' style={{ textAlign: 'left' }}>
							<Form.Label style={{ marginBottom: 8 }}>Numéro client*</Form.Label>
							<Form.Control onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClientNo(e.target.value)} value={clientNo} placeholder='Exemple : C0116067' style={{ width: 360 }} />
						</Form.Group>

						<Form.Group className='mb-3' style={{ textAlign: 'left' }}>
							<Form.Label style={{ marginBottom: 8 }}>Numéro de facture*</Form.Label>
							<Form.Control onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBillNo(e.target.value)} value={billNo} placeholder='Exemple : 5469886' style={{ marginBottom: 12, width: 360 }} />
						</Form.Group>
					</div>
				) : (
					<></>
				)}

				{payzenFormDisplay ? (
					<div className='form' style={{ margin: '20px 0' }}>
						<div className='container' style={{ display: 'flex', justifyContent: 'center' }}>
							<div id='myPaymentForm'></div>
						</div>
					</div>
				) : (
					<></>
				)}

				<Button disabled={predFormDisplay ? (!clientNo || !billNo ? true : isLoading) : isLoading} onClick={submitForm} variant='primary' type='submit' style={{ marginBottom: 32, width: 360 }}>
					{predFormDisplay ? 'Rechercher la facture' : 'Retour'}
				</Button>
			</Form>

			<Form.Text>
				Un problème ?{' '}
				<a href={env('PRED_CONTACT_URL')} target='_blank' rel='noreferrer'>
					Contactez-nous
				</a>{' '}
				|{' '}
				<a href='https://pred.fr' target='_blank' rel='noreferrer'>
					pred.fr
				</a>
			</Form.Text>
		</div>
	)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
