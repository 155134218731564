import { AxiosResponse } from 'axios'

import { env, payzenApi, predApi } from './config'

export const xmlAction = async (action: string, clientNo: string, billNo: string, amount?: number, transactionRef?: string): Promise<any> => {
	const res: AxiosResponse<any, any> = await predApi.post(
		'',
		`<?xml version="1.0" encoding="UTF-8"?>
			<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
				<soap:Body>
					<WebServiceDiva xmlns="http://www.Divalto.fr/WebService/">
						<action>&lt;action&gt;${action}</action>
						<param>
							&lt;FACTURE&gt;
								&lt;CODE_CLIENT&gt;${clientNo}&lt;/CODE_CLIENT&gt;
								&lt;FANO&gt;${billNo}&lt;/FANO&gt;
								${
									action === 'FACTURE_REGLER' ?
									`&lt;REGLMT&gt;${amount}&lt;/REGLMT&gt;
									&lt;REFTRANSAC&gt;${transactionRef}&lt;/REFTRANSAC&gt;`
									: ''
								}
							&lt;/FACTURE&gt;
						</param>
					</WebServiceDiva>
				</soap:Body>
			</soap:Envelope>`
	)

	return res.data
}

export const generateFormToken = async (clientNo: string, billNo: string, amount: number): Promise<string> => {
	let expirationDate: Date = new Date()
	expirationDate.setHours(expirationDate.getHours() + 1)

	const res: AxiosResponse<any, any> = await payzenApi.post('Charge/CreatePayment', {
		amount: amount * 100,
		currency: 'EUR',
		orderId: `${clientNo}-${billNo}`,
		customer: {
			email: env('PRED_BILLING_EMAIL')
		},
	})

	return res.data.answer.formToken
}

export const updateBillStatus = async (amount: number, clientNo: any, billNo: any): Promise<any> => {
	try {
		const res: any = await xmlAction('FACTURE_REGLER', clientNo, billNo, amount, `${clientNo}-${billNo}`)
		if (res.includes('non intégrée en comptabilité')) alert('Le paiement a été réalisé avec succès mais la facture n\'a pas été marquée comme réglée car elle n\'est pas intégrée en comptabilité.')
	} catch (err: any) {
		console.error(err)
		return err
	}
}