import axios, { AxiosInstance } from 'axios'

export const env = (envVar: string): string => {
	return process.env['REACT_APP_' + envVar] ?? ''
}

const corsBypassUrl: string = env('CORS_BYPASS_URL')

export const payzenApi: AxiosInstance = axios.create({
	baseURL: `${corsBypassUrl}/${env('PAYZEN_API_URL')}`,
	headers: {
		Authorization: `Basic ${window.btoa(env('PAYZEN_API_USER') + ':' + env('PAYZEN_API_PRODUCTION_PASSWORD'))}`,
		origin: 'web'
	}
})

export const predApi: AxiosInstance = axios.create({
	baseURL: `${corsBypassUrl}/${env('PRED_DIVALTO_API_URL')}`,
	headers: {
		'Content-Type': 'text/xml; charset=utf-8',
		origin: 'web'
	}
})
